<template>
    <div style="background-color: #0b0a1f;">
      <div class="week_bg_" style="background-image: url(img/athleticsRank/fireStar/ww_bg.gif);">
        <div class="week_back_" style="background-image: url(img/athleticsRank/fireStar/w_back.png);" @click="goBack"></div>
      </div>
      <div class="w_bg_other_">
        <div class="week_awards_" style="background-image: url(img/athleticsRank/fireStar/week_awards.png);"></div>
        <div class="week_beself">本活动最终解释权归多乐所有</div>
      </div>
  
    </div>
  </template>
    
  <script>
  import "@/assets/css/base.css"
  import "@/assets/css/athletics.css"
  
  export default {
    name: 'weekStarRule',
    data() {
      return {
        actDate: ""
      }
    },
    created() {
      this.actDate = this.$route.query.date
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      }
    }
  }
  </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped></style>
    